import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { NVOSContext } from "../../../context/NVOSContext";

// image
import logo from "../../../images/custom/nvos.png";
import { default as emojis } from "../../emojis"


class Login extends React.Component {
  static contextType = NVOSContext

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    
    this.state = {
      result: ""
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    
    fetch(global.url + 'index.php?route=login/login/ajaxvalidate', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
          console.log(result);

          if(result.result === 'success') {
            this.context.setUser(result.user);
            this.props.history.push("/zorgplannen");
          } else {
              this.setState({
                result: result.result
            })
          }
      },
      (error) => {
          this.setState({
            result: "failed"
          })
          console.log("Registreren mislukt! ", error);
      }
    ).then(window.scrollTo(0, 0));
  }

  render() {
    return (
      <div className="row no-gutters">
      <div className="col-xl-12">
        <div className="auth-form">
          <div className="text-center mb-3">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <h4 className="text-center mb-4 ">Inloggen</h4>
          {this.state.result === "failed" &&
            <Alert
              variant="danger"
              className="fade show"
            >
              {emojis.error}
              <strong>Mislukt!</strong> Inloggen mislukt. Controleer de ingelogde gegevens en probeer het opnieuw.
            </Alert>
          }
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label className="mb-1 ">
                <strong>E-mailadres</strong>
              </label>
              <input
                name="username"
                type="email"
                className="form-control"
                placeholder="voorbeeld@domein.com"
                defaultValue=""
              />
            </div>
            <div className="form-group">
              <label className="mb-1 ">
                <strong>Wachtwoord</strong>
              </label>
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Wachtwoord"
                defaultValue=""
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-block"
              >
                Inloggen
              </button>
            </div>
          </form>
          <div className="new-account mt-3">
            <p className="pull-left">
                <Link className="" to="wachtwoord-vergeten">
                  Wachtwoord vergeten?
                </Link>
            </p>
            <p className="pull-right">
              Nog geen account?{" "}
              <Link className="text-primary" to="./nieuw-account">
                Registeren
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default withRouter(Login);