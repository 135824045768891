import React from "react";
import { withRouter } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";
import { Alert } from "react-bootstrap";
import { default as emojis } from "../../emojis"

class WachtwoordWijzigen extends React.Component {
    
    static contextType = NVOSContext

    constructor(props) {
      super(props)

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
          result: ""
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        
        fetch(global.url + 'index.php?route=login/login/wachtwoordWijzigen', {
            method: 'POST',
            body: data,
          }).then(res => res.json())
          .then(
            (result) => {
                this.setState({
                  result: result.result
                });
            },
            (error) => {
                this.setState({
                  result: "failed"
                })
                console.log("Ophalen mislukt! ", error);
            }
          ).then(window.scrollTo(0, 0));
    }

    render() {
        return (
            <div className="row no-gutters">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title">Wachtwoord wijzigen</h5>
                        </div>
                        <div class="card-body">
                            {this.state.result === "failed" &&
                                <Alert
                                variant="danger"
                                className="fade show"
                                >
                                {emojis.error}
                                <strong>Mislukt!</strong> Wijzigen mislukt. Controleer uw huidige wachtwoord.
                                </Alert>
                            }
                            {this.state.result === "success" &&
                                <Alert
                                    variant="success"
                                    className="fade show"
                                >
                                    {emojis.error}
                                    <strong>Gelukt!</strong> Uw wachtwoord is gewijzigd!
                                </Alert>
                            }
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Huidig wachtwoord</strong>
                                </label>
                                <input
                                    name="oldpass"
                                    type="password"
                                    className="form-control"
                                    placeholder="Huidig wachtwoord"
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Nieuw wachtwoord</strong>
                                </label>
                                <input
                                    name="newpass"
                                    type="password"
                                    className="form-control"
                                    placeholder="Nieuw wachtwoord"
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Herhaal nieuw wachtwoord</strong>
                                </label>
                                <input
                                    name="newpasscopy"
                                    type="password"
                                    className="form-control"
                                    placeholder="Herhaal nieuw wachtwoord"
                                />
                                <input name="wachtwoordwijzigen" value="ja" type="hidden" />
                                </div>
                                <div className="text-center mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                >
                                    Wachtwoord wijzigen 
                                </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
  }  

export default withRouter(WachtwoordWijzigen);