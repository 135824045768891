import React, { Fragment } from "react";
import { ListGroup } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";

class ZorgplannenConcept extends React.Component {
    
    static contextType = NVOSContext

    constructor(props) {
      super(props)

        this.handleClick = this.handleClick.bind(this);
        
        this.state = {
            zorgID: 0,
            content: '',
            zorgplannen: []
        }
    }
  
    componentDidMount() {
        fetch(global.url + 'index.php?route=app/app/concepten').then(res => res.json())
          .then(
            (result) => {
                this.setState({
                    zorgplannen: result
                })
            },
            (error) => {
                console.log("Ophalen mislukt! ", error);
            }
          ).then(window.scrollTo(0, 0));
    }
    
    handleClick(id, del) {
        if(del) {
            fetch(global.url + 'index.php?route=app/app/concepten&del=' + id).then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        zorgplannen: result
                    })
                },
                (error) => {
                    console.log("Ophalen mislukt! ", error);
                }
            ).then(window.scrollTo(0, 0));
            
            return;
        }
        this.props.history.push("/nieuw-zorgplan#" + id);
    }

    componentWillMount() {
        if (this.props.location.hash !== '') {
            let zorgPlanID = this.props.location.hash.replace("#","");
            
            if(this.state.zorgID !== zorgPlanID) this.getZorgPlan(zorgPlanID);
        }
    }

      componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if(this.props.location.hash === '') {
                this.setState({
                    zorgID: 0,
                    content: ''
                  })
            } else {
                let zorgPlanID = this.props.location.hash.replace("#","");

                if(this.state.zorgID !== zorgPlanID) this.getZorgPlan(zorgPlanID);
            }
        }
    }

    render() {
        if(this.state.content !== '') {
            return (
                <div className="row no-gutters">
                    <div class="col-xl-12">
                        <div dangerouslySetInnerHTML={{__html:this.state.content}}></div>
                    </div>
                </div>
            )
        } else {
            if(this.state.zorgplannen.length > 0) {
                return (
                    <div className="row no-gutters">
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title">Zorgplannen (concepten)</h5>
                                </div>
                                <div class="card-body">
                                    <div className="basic-list-group">
                                        <ListGroup>
                                        {this.state.zorgplannen.map((zorgplan, i) => (
                                            <Fragment key={i}>
                                                <ListGroup.Item action onClick={() => this.handleClick(zorgplan.zorgplanid)}>
                                                    <span className="pull-right">
                                                        <span className="btn btn-sm btn-danger" onClick={(e) => { this.handleClick(zorgplan.zorgplanid, true); e.stopPropagation(); }}>
                                                            <i className="fa fa-times" /> <span class="d-none d-lg-inline-block">verwijderen</span>
                                                        </span>
                                                    </span>
                                                    <strong>{zorgplan.zorgplanid}</strong> ({zorgplan.GeboorteJaar})<br />
                                                    Aangemaakt op {zorgplan.datumtijd_start} 
                                                </ListGroup.Item>
                                            </Fragment>
                                        ))}
                                        </ListGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="row no-gutters">
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title">Concepten</h5>
                                </div>
                                <div class="card-body">
                                    <p>Er zijn geen concepten, zorgplannen die nog moeten afgerond worden komen in dit scherm terecht.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        
    }
  }  

export default withRouter(ZorgplannenConcept);