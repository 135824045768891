/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { NVOSContext } from "../../../context/NVOSContext";
import { ThemeContext } from "../../../context/ThemeContext";
//import book from "../../../images/custom/nvos_icon.png";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const {
    user
  } = useContext(NVOSContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }

        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let dashboard = [
        "",
        "dashboard"
    ],
    zorgplannen = [
        "controleer-zorgplan-id",
        "concepten",
        "nieuw-zorgplan", 
        "zorgplannen"
    ],
    account = [
        "nieuw-account", 
        "inloggen",
        "mijn-gegevens", 
        "wachtwoord-wijzigen"
    ],
    informatie = [
        "richtlijnen",
        "disclaimer"
    ];
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${dashboard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul >
              <li>
                <Link
                  className={`${path === "dashboard" ? "mm-active" : ""}`}
                  to="/dashboard"
                >
                  Home
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${zorgplannen.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-heart"></i>
              <span className="nav-text">Zorgplannen</span>
            </Link>
            <ul >
              {user != null &&
              <li>
                <Link
                  className={`${path === "nieuw-zorgplan" ? "mm-active" : ""}`}
                  to="/nieuw-zorgplan"
                >
                  Nieuw
                </Link>
              </li>
              }
              {user && user.user_id != null &&
              <li>
                <Link
                  className={`${path === "concepten" ? "mm-active" : ""}`}
                  to="/concepten"
                >
                  Concept
                </Link>
              </li>
              }
              {user && user.user_id != null &&
              <li>
                <Link
                  className={`${path === "zorgplannen" ? "mm-active" : ""}`}
                  to="/zorgplannen"
                >
                  Afgerond
                </Link>
              </li>
              }
              {user && user.user_id != null &&
              <li>
                <Link
                  className={`${path === "kopieren-zorgplan" ? "mm-active" : ""}`}
                  to="/kopieren-zorgplan"
                >
                  Kopieren
                </Link>
              </li>
              }
              <li>
                <Link
                  className={`${path === "controleer-zorgplan-id" ? "mm-active" : ""}`}
                  to="/controleer-zorgplan-id"
                >
                  Controleer
                </Link>
              </li>
            </ul>
          </li>
          {(user == null || user.user_id != null) &&
          <li className={`${account.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-user-7"></i>
              <span className="nav-text">Account</span>
            </Link>
            {user == null &&
            <ul >
              <li>
                <Link
                  className={`${path === "nieuw-account" ? "mm-active" : ""}`}
                  to="/nieuw-account"
                >
                  Nieuw account
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "inloggen" ? "mm-active" : ""}`}
                  to="/inloggen"
                >
                  Inloggen
                </Link>
              </li>
            </ul>
            }
            {user && user.user_id != null &&
            <ul >
              <li>
                <Link
                  className={`${path === "mijn-gegevens" ? "mm-active" : ""}`}
                  to="/mijn-gegevens"
                >
                  Mijn gegevens
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "wachtwoord-wijzigen" ? "mm-active" : ""}`}
                  to="/wachtwoord-wijzigen"
                >
                  Wachtwoord wijzigen
                </Link>
              </li>
            </ul>
            }
          </li>
          }
          <li className={`${informatie.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-layer-1"></i>
              <span className="nav-text">Informatie</span>
            </Link>
            <ul >
              <li>
                <Link
                  className={`${path === "richtlijnen" ? "mm-active" : ""}`}
                  to="/richtlijnen"
                >
                  Richtlijnen inzien
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "disclaimer" ? "mm-active" : ""}`}
                  to="/disclaimer"
                >
                  Disclaimer
                </Link>
              </li>
            </ul>
          </li>
        </MM>
        <div className="copyright">
          <p>
          <strong>NVOS-Orthobanda</strong> &copy; 2021 Alle rechten voorbehouden
          </p>
          <p className="fs-12">
          Ontwikkeld door <span className="heart"></span> Gewoon Peter
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
