import React from "react";

const Disclaimer = () => {
  return (
    <div className="row no-gutters">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Disclaimer</h5>
                </div>
                <div class="card-body">
                    <p>De informatie op deze website en op afgeleide producten van deze website is met de grootst mogelijke zorgvuldigheid samengesteld. De vereniging NVOS-Orthobanda sluit iedere aansprakelijkheid voor de opmaak en de inhoud van de richtlijnen alsmede voor de gevolgen die de toepassing van de richtlijnen in de patiëntenzorg mocht hebben uit. De Indicatieportaaldatabase stelt zich daarentegen wel open voor attendering op (vermeende) fouten in de opmaak of inhoud van de richtlijnen. Men neme daartoe contact op middels e-mail: <a href="mailto:info@indicatieportaal.nl">info@indicatieportaal.nl</a></p>

                    <h4>Juridische betekenis van richtlijnen</h4>
                    <p>Richtlijnen bevatten aanbevelingen van algemene aard. Het is mogelijk dat deze aanbevelingen in een individueel geval niet van toepassing zijn. Er kunnen zich feiten of omstandigheden voordoen waardoor het wenselijk is dat in het belang van de patiënt van de richtlijn wordt afgeweken. Wanneer van een richtlijn wordt afgeweken, dient dit beargumenteerd gedocumenteerd te worden. De toepasbaarheid en de toepassing van de richtlijnen in de praktijk is de verantwoordelijkheid van de behandelend zorgverlener.</p>

                    <h4>Intellectuele eigendomsrechten</h4>
                    <p>De intellectuele eigendomsrechten met betrekking tot de afgeleide producten van deze website berusten bij de houder van de richtlijn. Het is de gebruiker van deze site niet toegestaan de inhoud van richtlijnen (gedeeltelijk) te verveelvoudigen en/of openbaar te maken, zonder de uitdrukkelijke schriftelijke toestemming van NVOS-Orthobanda. U kunt een verzoek voor toestemming richten aan NVOS-Orthobanda, Postbus 120, 3765 AC Soest.</p>

                    <h4>Bescherming persoonsgegevens</h4>
                    <p>Door gebruikers verstrekte persoonsgegevens ten behoeve van de mailservice of de inlogmogelijkheid van www.indicatieportaal.nl worden door NVOS-Orthobanda vertrouwelijk behandeld. Gegevens worden zonder toestemming niet verstrekt aan derden. Zie voor de wijze waarop NVOS-Orthobanda uw gegevens verwerkt:<br /><br /><a href="http://www.nvos-orthobanda.nl/pages/271/Privacy-statement.html" rel="noreferrer" target="_blank">Privacy statement NVOS</a></p>	
                </div>
            </div>
        </div>
    </div>
  );
};

export default Disclaimer;
