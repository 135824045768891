import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Row, Card, Col, ListGroup, Accordion } from "react-bootstrap";
import { NVOSContext } from "../../../context/NVOSContext";
import Nestable from "react-nestable";

class Richtlijnen extends React.Component {
    
    static contextType = NVOSContext

    constructor(props) {
      super(props)
        console.log("Richtlijnen pagina geopend!");
        
        this.state = {
            title: 'Richtlijnen inzien',
            lastPage: false,
            selectedIndex: null,
            currentResults: [],
            activeDefault: 0,
            breadCrumb: null,
        }
    }
  
    componentWillMount() {
        if(this.context.richtlijnen.length === 0) {
            this.context.getRichtlijnen().then(() => {
                console.log('Richtlijnen opgehaald!');

                this.setState({
                    title: 'Richtlijnen inzien',
                    lastPage: false,
                    selectedIndex: null,
                    currentResults: this.context.richtlijnen
                })
            }).then(() => {
                if (this.props.location.hash !== '') {
                    this.goToHash(this.props.location.hash);
                }
            });
        } else {
            console.log("Richtlijnen uit cache", this.context.richtlijnen);
            
            this.setState({
                title: 'Richtlijnen inzien',
                lastPage: false,
                selectedIndex: null,
                currentResults: this.context.richtlijnen
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.goToHash(this.props.location.hash);
        }
    }

    goToHash = (hash) => {
        console.log('Received location hash', hash);
            
        hash = hash.split("#");
        hash = hash[hash.length - 1];

        console.log('Converted to internal hash', hash);

        if(hash === '') {
            this.setState({
                title: 'Richtlijnen inzien',
                lastPage: false,
                selectedIndex: null,
                currentResults: this.context.richtlijnen,
                activeDefault: 0,
                breadCrumb: null
            })
        } else {
            let pages = (hash + '').split('-');
            let newBreadCrumb = null,
                result = [],
                lastIndex = null;

            for (let i = 0; i < pages.length; i++) {
                if(i === 0) {
                    result = this.context.richtlijnen[pages[i]];
                } else {
                    result = result.items[pages[i]];
                }
                newBreadCrumb = (newBreadCrumb === null) ? i : newBreadCrumb + '-' + i;
                lastIndex = i;
            }

            if(result.lastPage) {
                result.items = this.processTree(result.items);
            }

            this.setState({
                title: result.title,
                lastPage: result.lastPage,
                selectedIndex: lastIndex,
                currentResults: result.items,
                breadCrumb: newBreadCrumb
            })
        }
    }

    backButton = () => {
        if(this.state.breadCrumb != null) {
            console.log('breadCrumb', this.state.breadCrumb);
            let pages = (this.state.breadCrumb + '').split('-');

                if(pages.length === 1) {
                    this.setState({
                        title: 'Richtlijnen inzien',
                        lastPage: false,
                        selectedIndex: null,
                        currentResults: this.context.richtlijnen,
                        activeDefault: 0,
                        breadCrumb: null
                    })
                } else {
                    let newBreadCrumb = null,
                        result = [],
                        lastIndex = null;

                    for (let i = 0; i < (pages.length - 1); i++) {
                        if(i === 0) {
                            result = this.context.richtlijnen[pages[i]];
                        } else {
                            result = result.items[pages[i]];
                        }
                        newBreadCrumb = (newBreadCrumb === null) ? i : newBreadCrumb + '-' + i;
                        lastIndex = i;
                    }

                    if(result.lastPage) {
                        result.items = this.processTree(result.items);
                    }

                    this.setState({
                        title: result.title,
                        lastPage: result.lastPage,
                        selectedIndex: lastIndex,
                        currentResults: result.items,
                        breadCrumb: newBreadCrumb
                    })
                }
        }
    }

    itemClicked = (i) => {
        console.log("itemClicked", i);

        var array = (this.state.selectedIndex != null) ? this.state.currentResults : this.context.richtlijnen,
            newBreadCrumb = (this.state.breadCrumb == null) ? i : this.state.breadCrumb + '-' + i;

        if(array[i].lastPage) {
            array[i].items = this.processTree(array[i].items);
        }

        this.setState({
            title: array[i].title,
            lastPage: array[i].lastPage,
            selectedIndex: i,
            currentResults: array[i].items,
            breadCrumb: newBreadCrumb
        })
        
        this.props.history.push("/richtlijnen#" + newBreadCrumb);
    }

    setActiveDefault = (i) => {
        this.setState({
            activeDefault: i
        })
    }
    
    htmldecode = (str) => {
        var txt = document.createElement('textarea');
            txt.innerHTML = str;

        return txt.value;
    }

    renderItem = ({ item, collapseIcon, handler }) => {
        return (
           <div>
              {handler}
              {collapseIcon}
              {item.text}
           </div>
        );
     }

    processTree(currentItems) {
        for (let i = 0; i < currentItems.length; i++) {
            let richtlijn = currentItems[i];
            let tree = JSON.parse(richtlijn.tree);
            let items = [];

            for (const [index, element] of Object.entries(tree)) {
                for (let ii = 1; ii < 8; ii++) {
                    if(element["Radiobutton"+ii] !== "") {
                        let children = [];

                        if(element.sub) {
                            for (const [index1, element1] of Object.entries(element.sub)) {
                                let children2 = [];
                                for (let iii = 1; iii < 8; iii++) {
                                    if(element1["Radiobutton"+iii] !== "") {
                                        let children3 = [];
                                        if(element1.sub) {
                                            for (const [index2, element2] of Object.entries(element1.sub)) {
                                                let children4 = [];
                                                for (let iiii = 1; iiii < 8; iiii++) {
                                                    if(element2["Radiobutton"+iiii] !== "") {
                                                        children4.push({ id: iiii, text: element2["Radiobutton"+iiii] });
                                                    }
                                                }
                                                children3.push({ id: iii + "" + index2, text: element2["Korte omschrijving"], children: children4 });
                                            }
                                        }
                                        children2.push({ id: iii + "" + index1, text: element1["Radiobutton"+iii], children: children3 });
                                    }
                                }
                                children.push({ id: ii + "" + index1, text: element1["Korte omschrijving"], children: children2 });
                            }
                        }
                        items.push({ id: ii + "" + index, text: element["Radiobutton"+ii], children: children });
                    }
                }
            }

            currentItems[i].children = items;
        }

        return currentItems;
    }

    render() {
        if(this.state.lastPage) {
            return (
                <Fragment>
                <Row>
                  <Col xl="12">
                  <Card>
                    <Card.Header className="d-block card-header">
                        <Card.Title>{this.state.title}</Card.Title>
                    </Card.Header>
                    <Card.Body className="card-body">
                    <Accordion
                        className="accordion accordion-primary"
                        defaultActiveKey="0"
                    >
                        {this.state.currentResults.map((richtlijn, i) => (
                        <div className="accordion__item" key={i}>
                            <Accordion.Toggle
                            as={Card.Text}
                            eventKey={`${i}`}
                            className={`accordion__header rounded-lg ${
                                this.state.activeDefault === i ? "" : "collapsed"
                            }`}
                            onClick={() =>
                                this.setActiveDefault(this.state.activeDefault === i ? -1 : i)
                            }
                            >
                            <span className="accordion__header--text">{this.htmldecode(richtlijn.title)}</span>
                            <span className="accordion__header--indicator"></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${i}`}>
                            <div className="accordion__body--text">
                                <div dangerouslySetInnerHTML={{__html:this.htmldecode(richtlijn.description)}}></div>
                                {richtlijn.tree !== "" &&
                                    <div>
                                        <div className="nestable">
                                            <div className="dd" id="nestable">
                                            <ol className="dd-list">
                                                <li className="dd-item" data-id="1">
                                                    <Nestable
                                                        items={richtlijn.children}
                                                        renderItem={this.renderItem}
                                                        maxDepth ={30}
                                                    />
                                                </li>
                                            </ol>
                                            </div>
                                        </div>
                                    </div>
                                    //<div dangerouslySetInnerHTML={{__html:"<pre>"+ richtlijn.tree +"</pre>"}}></div>
                                }
                            </div>
                            </Accordion.Collapse>
                        </div>
                        ))}
                    </Accordion>
                    </Card.Body>
                </Card>
                  </Col>
                </Row>
              </Fragment>
            )
        } else {
            return (
                <Fragment>
                <Row>
                  <Col xl="12">
                    <Card>
                      <Card.Header>
                        <Card.Title>{this.state.title}</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                            <div className="basic-list-group">
                                <ListGroup>
                                {this.state.currentResults.map((richtlijn, i) => {     
                                    if(richtlijn.items) {
                                        return (
                                            <Fragment key={i}>
                                                <ListGroup.Item action onClick={() => this.itemClicked(i)}>{this.htmldecode(richtlijn.title)}</ListGroup.Item>
                                            </Fragment>
                                        )
                                    } else {
                                        return (
                                            <Fragment key={i}>
                                                <ListGroup.Item>{this.htmldecode(richtlijn.title)}</ListGroup.Item>
                                            </Fragment>
                                        )
                                    }
                                })}
                                </ListGroup>
                            </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Fragment>
            )
        }
    }
  }  

export default withRouter(Richtlijnen);