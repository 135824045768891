import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
import { Dropdown } from "react-bootstrap";


class Header extends React.Component {
  static contextType = NVOSContext

  // constructor(props) {
  //   super(props)
  // }

  logOut = () => {
    this.context.logOutUser();
    this.props.history.push("/inloggen");
  }

  render() {
    return (
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div
                  className="dashboard_bar"
                  style={{ textTransform: "capitalize" }}
                >
                    Indicatieportaal
                </div>
              </div>
  
              <ul className="navbar-nav header-right">
              {this.context.user == null &&
                <Dropdown
                  as="li"
                  className="nav-item dropdown notification_dropdown "
                >
                  <Link
                    to="/inloggen"
                    className="nav-link bell bell-link i-false c-pointer"
                  >
                    <i className="flaticon-381-lock-1" /> Inloggen
  
                    <div className="pulse-css" />
                  </Link>
                </Dropdown>
                }
                {this.context.user != null &&
                <Dropdown as="li" className="nav-item dropdown header-profile">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer"
                    // href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    {this.context.user.user_id != null &&
                      <div className="header-info">
                        <span>{this.context.user.voornaam} {this.context.user.achternaam}</span>
                        <small>{this.context.user.bedrijfsnaam}</small>
                      </div>
                    }
                    {this.context.user.extKey != null &&
                      <div className="header-info">
                        <span>{this.context.user.extKey}</span>
                      </div>
                    }
                  </Dropdown.Toggle>
  
                  <Dropdown.Menu align="right" className="mt-2">
                    <Link className="dropdown-item ai-icon" to="/inloggen" onClick={this.logOut.bind(this)}>
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ml-2">Uitloggen </span>
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
                }
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default withRouter(Header);
