import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";

import { Wizard, Steps, Step } from 'react-albus';
import { Button, Accordion, Card } from "react-bootstrap";

const successStyle = {backgroundColor: "#ecfae4", borderColor: "#ecfae4", color: "#68cf29" }

class Enquete extends React.Component {
  static contextType = NVOSContext

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

      this.state = {
        content: '',
        zorgplan: null,
        formData: null,
        data: [],
        result: null
      }
  }

  componentDidMount() {
    var hash = this.props.location.hash;
    
    if(hash != null && hash !== '') {
      hash = hash.split("#");
      hash = hash[hash.length - 1];

      var type = 1;

      fetch(global.url + 'index.php?route=app/app/enquetevragen&zorgid=' + hash).then(response => {
          const contentType = response.headers.get('Content-Type'); // -> "text/html; charset=utf-8"
        
          if (/text\/html/i.test(contentType)) {
            type = 0;
            return response.text();
          } else if (/application\/json/.test(contentType)) {
            type = 1;
            return response.json();
          } 
        }).then(
          (result) => {
            if(type === 1) {
              this.setState({
                zorgplan: hash,
                data: result
              });
            } else {
              this.setState({
                content: result
              });
            }
          },
          (error) => {
              console.log("Ophalen mislukt! ", error);
          }
        ).then(window.scrollTo(0, 0));
    } else {
      this.props.history.push("/zorgplannen");
    }
  }

  componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
          this.componentDidMount();
      }
  }

  handleSubmit(event) {
      event.preventDefault();
      const newFormData = new FormData(event.target.form);
      
      fetch(global.url + 'index.php?route=app/app/ajaxEnqueteOpslaan', {
          method: 'POST',
          body: newFormData,
        }).then(res => res.json())
        .then(
          (result) => {
              this.setState({
                result: result.result
              });
          },
          (error) => {
              this.setState({
                result: "failed"
              })
              console.log("Opslaan mislukt! ", error);
          }
        ).then(window.scrollTo(0, 0));
  }

  handleChange = (e) => {
    const newFormData = new FormData(e.target.form);
    
    if(this.state.formData != null) {
      for (var pair of this.state.formData.entries()) {
        if(newFormData.get(pair[0]) == null) newFormData.set(pair[0], pair[1]);
      }
    }
    
    this.setState({ formData: newFormData });
  }

  checkEnquetevraag = (vraag) => {
    var result = {style: null, keuze: 0};

    if(this.state.formData == null) return result;

    var current = this.state.formData.get(`enquete[${vraag.enqvraagid}][antwoord]`);

    if(current != null) {
      result.style = successStyle;
    }

    return result;
  }

  render() {
    if(this.state.content !== '') {
        return (
            <div className="row no-gutters">
                <div className="col-xl-12">
                    <div onClick={this.clickHandler} dangerouslySetInnerHTML={{__html:this.state.content}}></div>
                </div>
            </div>
        )
    } else {
      if(this.state.result == null) {
        return (
          <Fragment>
            <div className="row">
              <div className="col-xl-12 col-xxl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Enquete</h4>
                  </div>
                  <div className="card-body">
                    <form
                      onSubmit={(e) => {e.preventDefault()}}
                      id="step-form-horizontal"
                      className="step-form-horizontal"
                    >
                      <Wizard>
                        <Steps>
                          <Step
                            id="enquete"
                            render={() => (
                              <section>
                              <p class="text-muted"><strong class="text-info">Zorgplan: {this.state.zorgplan != null && this.state.zorgplan}</strong></p>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                    <input type="hidden" name="zorgplanid" value={this.state.zorgplan != null && this.state.zorgplan} /> 
                                    <Accordion
                                        className="accordion-primary"
                                        defaultActiveKey="1"
                                    >
                                        {this.state.data.map((vraag) => {
                                          var check = this.checkEnquetevraag(vraag);
                                          return (
                                            <div className="accordion__item" key={vraag.enqvraagid}>
                                                <Accordion.Toggle
                                                as={Card.Text}
                                                eventKey={`${vraag.enqvraagid}`}
                                                className={`accordion__header rounded-lg collapsed`}
                                                style={check.style}
                                                >
                                                <span className="accordion__header--text">{vraag.Vraag}</span>
                                                <span className="accordion__header--indicator"></span>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={`${vraag.enqvraagid}`}>
                                                <div className="accordion__body--text">
                                                  <p><span class="text-info">{vraag.Vraag}</span></p>
                                                  <div className="form-group mb-0">
                                                    <div className="radio">
                                                      <label>
                                                          <input onChange={this.handleChange} type="radio" name={`enquete[${vraag.enqvraagid}][antwoord]`} value="" /> Negeer deze vraag
                                                      </label>
                                                    </div>
                                                    {Array.from(Array(8), (e, ri) => {
                                                      if(ri > 0 && vraag["Radiobutton"+ri] !== "") {
                                                        return (
                                                          <div className="radio">
                                                            <label>
                                                              <input onChange={this.handleChange} type="radio" name={`enquete[${vraag.enqvraagid}][antwoord]`} value={vraag["Radiobutton"+ri]} /> {vraag["Radiobutton"+ri]}
                                                            </label>
                                                          </div>
                                                        )
                                                      } else return '';
                                                    })}
                                                  </div>
                                                  <div className="form-group">
                                                      <input 
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        name={`enquete[${vraag.enqvraagid}][txt]`}
                                                        placeholder={vraag.Tekstveldomschrijving}
                                                        className="form-control"
                                                      />
                                                  </div>
                                                </div>
                                                </Accordion.Collapse>
                                            </div>
                                        )})}
                                    </Accordion>
                                    </div>
                                </div>
                                <Button onClick={(e) => {this.handleSubmit(e);}} className="mr-2" variant="primary">Opslaan</Button>
                              </section>
                            )}
                          />
                        </Steps>
                      </Wizard>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )
      } else if(this.state.result === 'success') {
        return (
          <div className="row no-gutters">
              <div class="col-xl-12">
                  <div class="card">
                      <div class="card-header">
                          <h5 class="card-title">Enquete</h5>
                      </div>
                      <div class="card-body">
                          <p class="text-success">Enquete antwoorden zijn succesvol opgeslagen!</p>
                      </div>
                  </div>
              </div>
          </div>
      )
      } else if(this.state.result === 'failed') {
        return (
          <div className="row no-gutters">
              <div class="col-xl-12">
                  <div class="card">
                      <div class="card-header">
                          <h5 class="card-title">Enquete</h5>
                      </div>
                      <div class="card-body">
                          <p class="text-danger">Er is helaas een onbekende fout opgetreden bij het opslaan van uw antwoorden.</p>
                      </div>
                  </div>
              </div>
          </div>
      )
      }
    }
  }
};

export default withRouter(Enquete);
