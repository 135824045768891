import React from "react";
import { withRouter } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";
import { Alert } from "react-bootstrap";
import { default as emojis } from "../../emojis"

class Account extends React.Component {
    
    static contextType = NVOSContext

    constructor(props) {
      super(props)

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
          result: ""
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        
        fetch(global.url + 'index.php?route=login/login/accountBewerken', {
            method: 'POST',
            body: data,
          }).then(res => res.json())
          .then(
            (result) => {
                if(result.result === 'success') {
                    var user = {...this.context.user}

                    for(var pair of data.entries()) {
                        if(pair[0] !== 'klantbewerken') {
                            user[pair[0]] = pair[1];
                        }
                     }

                    this.context.setUser(user);
                }

                this.setState({
                  result: result.result
                });
            },
            (error) => {
                this.setState({
                  result: "failed"
                })
                console.log("Ophalen mislukt! ", error);
            }
          ).then(window.scrollTo(0, 0));
    }

    render() {
        return (
            <div className="row no-gutters">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title">Mijn gegevens</h5>
                        </div>
                        <div class="card-body">
                            {this.state.result === "failed" &&
                                <Alert
                                variant="danger"
                                className="fade show"
                                >
                                {emojis.error}
                                <strong>Mislukt!</strong> Updaten mislukt. Fout opgetreden op de server.
                                </Alert>
                            }
                            {this.state.result === "success" &&
                                <Alert
                                    variant="success"
                                    className="fade show"
                                >
                                    {emojis.error}
                                    <strong>Gelukt!</strong> Uw gegevens zijn geupdate!
                                </Alert>
                            }
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Bedrijfsnaam</strong>
                                </label>
                                <input
                                    name="bedrijf"
                                    type="text"
                                    className="form-control"
                                    placeholder="Bedrijfsnaam"
                                    defaultValue={this.context.user.bedrijfsnaam}
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Functie</strong>
                                </label>
                                <input
                                    name="functie"
                                    type="text"
                                    className="form-control"
                                    placeholder="Functie"
                                    defaultValue={this.context.user.functie}
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Certificaat nummer</strong>
                                </label>
                                <input
                                    name="certificaat"
                                    type="text"
                                    className="form-control"
                                    placeholder="Certificaat nummer"
                                    defaultValue={this.context.user.certificaat}
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Voornaam</strong>
                                </label>
                                <input
                                    name="voornaam"
                                    type="text"
                                    className="form-control"
                                    placeholder="Voornaam"
                                    defaultValue={this.context.user.voornaam}
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Achternaam</strong>
                                </label>
                                <input
                                    name="achternaam"
                                    type="text"
                                    className="form-control"
                                    placeholder="Achternaam"
                                    defaultValue={this.context.user.achternaam}
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Adres</strong>
                                </label>
                                <input
                                    name="adres"
                                    type="text"
                                    className="form-control"
                                    placeholder="Adres"
                                    defaultValue={this.context.user.adres}
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Woonplaats</strong>
                                </label>
                                <input
                                    name="woonplaats"
                                    type="text"
                                    className="form-control"
                                    placeholder="Woonplaats"
                                    defaultValue={this.context.user.woonplaats}
                                />
                                </div>
                                <div className="form-group">
                                <label className="mb-1 ">
                                    <strong>Postcode</strong>
                                </label>
                                <input
                                    name="postcode"
                                    type="text"
                                    className="form-control"
                                    placeholder="Postcode"
                                    defaultValue={this.context.user.postcode}
                                />
                                <input name="klantbewerken" value="ja" type="hidden" />
                                </div>
                                <div className="text-center mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                >
                                    Gegevens wijzigen 
                                </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
  }  

export default withRouter(Account);