import React, { Component, createContext } from "react";

export const NVOSContext = createContext();

export default class NVOSContextProvider extends Component {
    constructor(props) {
        super(props);

        this.setUser = this.setUser.bind(this);
        this.logOutUser = this.logOutUser.bind(this);
        this.getRichtlijnen = this.getRichtlijnen.bind(this);

        this.state = {
            user: JSON.parse(window.localStorage.getItem('user')) || null,
            setUser: this.setUser,
            logOutUser: this.logOutUser,
            richtlijnen: [],
            getRichtlijnen: this.getRichtlijnen
        }
    }
   
    setUser(user) {
        window.localStorage.setItem('user', JSON.stringify(user));
        this.setState({user: user});
    }

    logOutUser() {
        window.localStorage.removeItem('user');
        this.setState({user: null});

        fetch(global.url + "index.php?route=login/login/uitloggen");
    }

    getRichtlijnen() {
        return fetch(global.url + "index.php?route=app/app/richtlijnen")
        .then(res => res.json())
          .then(
            (result) => {
                this.setState({richtlijnen: result});
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                console.log("Richtlijnen ophalen mislukt! ", error);
            }
          );
    }
  
    render() {
        return (
            <NVOSContext.Provider value={this.state}>
                {this.props.children}
            </NVOSContext.Provider>
        );
    }
}