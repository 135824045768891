import React from "react";
import { withRouter } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";

class ZorgPlanId extends React.Component {
    
    static contextType = NVOSContext

    constructor(props) {
      super(props)

        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            zorgID: 0,
            content: ''
        }
    }
  
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        
        this.getZorgPlan(data.get('zorgid'));
    }

    getZorgPlan(id) {
        const data = new FormData();
        data.set('zorgid', id);

        fetch(global.url + 'index.php?route=mobile/mobile/htmlGetPlanID', {
            method: 'POST',
            body: data,
          }).then(res => res.text())
          .then(
            (result) => {
                this.setState({
                  zorgID: id,
                  content: result
                })
                
                  this.props.history.push("/controleer-zorgplan-id#" + this.state.zorgID);
            },
            (error) => {
                console.log("Ophalen mislukt! ", error);
            }
          ).then(window.scrollTo(0, 0));
      }

    componentWillMount() {
        if (this.props.location.hash !== '') {
            let zorgPlanID = this.props.location.hash.replace("#","");
            
            if(this.state.zorgID !== zorgPlanID) this.getZorgPlan(zorgPlanID);
        }
    }

      componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if(this.props.location.hash === '') {
                this.setState({
                    zorgID: 0,
                    content: ''
                  })
            } else {
                let zorgPlanID = this.props.location.hash.replace("#","");

                if(this.state.zorgID !== zorgPlanID) this.getZorgPlan(zorgPlanID);
            }
        }
    }

    render() {
        if(this.state.content !== '') {
            return (
                <div className="row no-gutters">
                    <div class="col-xl-12">
                        <div dangerouslySetInnerHTML={{__html:this.state.content}}></div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row no-gutters">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title">Zorgplan bekijken</h5>
                            </div>
                            <div class="card-body">
                                <div className="basic-form">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                        name="zorgid"
                                        type="text"
                                        className="form-control input-default "
                                        placeholder="Vul uw Zorgplan-ID in"
                                        />
                                    </div>
                                    <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                    >
                                        Zoeken 
                                    </button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
    }
  }  

export default withRouter(ZorgPlanId);