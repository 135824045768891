import React, { useContext } from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./pages/Home/Home";
/// Form

/// Pages
import Account from "./pages/Account/Account";
import Login from "./pages/Account/Login";
import Registration from "./pages/Account/Registration";
import WachtwoordWijzigen from "./pages/Account/WachtwoordWijzigen";
import ForgotPassword from "./pages/Account/ForgotPassword";

import Disclaimer from "./pages/Informatie/Disclaimer";
import Richtlijnen from "./pages/Informatie/Richtlijnen";

import ZorgPlanId from "./pages/Zorgplannen/ZorgPlanId";
import Zorgplannen from "./pages/Zorgplannen/Zorgplannen";
import ZorgplannenConcept from "./pages/Zorgplannen/ZorgplannenConcept";
import ZorgplanKopieren from "./pages/Zorgplannen/ZorgplanKopieren";
import ZorgplanMaken from "./pages/Zorgplannen/ZorgplanMaken";
import Enquete from "./pages/Zorgplannen/Enquete";

import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home, login: false },
    { url: "dashboard", component: Home, login: false },

    /// Zorgplannen
    { url: "nieuw-zorgplan", component: ZorgplanMaken, login: true },
    { url: "concepten", component: ZorgplannenConcept, login: true },
    { url: "kopieren-zorgplan", component: ZorgplanKopieren, login: true },
    { url: "enquete", component: Enquete, login: true },
    { url: "controleer-zorgplan-id", component: ZorgPlanId, login: false },

    /// Account (uitgelogd)
    { url: "nieuw-account", component: Registration, login: false },
    { url: "wachtwoord-vergeten", component: ForgotPassword, login: false },
    { url: "inloggen", component: Login, login: false },
    
    /// Account (ingelogd)
    { url: "mijn-gegevens", component: Account, login: true },
    { url: "wachtwoord-wijzigen", component: WachtwoordWijzigen, login: true },
    { url: "zorgplannen", component: Zorgplannen, login: true },

    /// Informatie
    { url: "richtlijnen", component: Richtlijnen, login: false },
    { url: "disclaimer", component: Disclaimer, login: false },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <Router basename="/app">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </Router>
  );
};

export default Markup;
