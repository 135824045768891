import React, { Fragment } from "react";
import { ListGroup } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";

class Zorgplannen extends React.Component {
    
    static contextType = NVOSContext

    constructor(props) {
      super(props)

        this.handleClick = this.handleClick.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        
        this.state = {
            zorgID: 0,
            content: '',
            zorgplannen: [],
            weergave: ''
        }
    }
  
    componentDidMount() {
        fetch(global.url + 'index.php?route=app/app/plannenbekijken').then(res => res.json())
          .then(
            (result) => {
                this.setState({
                    zorgplannen: result
                })
            },
            (error) => {
                console.log("Ophalen mislukt! ", error);
            }
          ).then(window.scrollTo(0, 0));
    }
    
    handleClick(id, copy) {
        if(copy && copy === 1) {
            fetch(global.url + 'index.php?route=app/app/plankopieren&zorgid=' + id).then(res => res.json())
            .then(
                (result) => {
                    this.props.history.push("/nieuw-zorgplan#" + result.newPlanID);
                },
                (error) => {
                    console.log("Ophalen mislukt! ", error);
                }
            ).then(window.scrollTo(0, 0));
        } else if(copy && copy === 2) {
            this.props.history.push("/enquete#" + id);
        } else {
            this.getZorgPlan(id);
        }
    }

    getZorgPlan(id) {
        const data = new FormData();
        data.set('zorgid', id);

        fetch(global.url + 'index.php?route=mobile/mobile/htmlGetPlanID' + this.state.weergave, {
            method: 'POST',
            body: data,
          }).then(res => res.text())
          .then(
            (result) => {
                this.setState({
                  zorgID: id,
                  content: result
                })
                
                  this.props.history.push("/zorgplannen#" + this.state.zorgID);
            },
            (error) => {
                console.log("Ophalen mislukt! ", error);
            }
          ).then(window.scrollTo(0, 0));
      }

    componentWillMount() {
        if (this.props.location.hash !== '') {
            let zorgPlanID = this.props.location.hash.replace("#","");
            
            if(this.state.zorgID !== zorgPlanID) this.getZorgPlan(zorgPlanID);
        }
    }

      componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if(this.props.location.hash === '') {
                this.setState({
                    zorgID: 0,
                    content: ''
                  })
            } else {
                let zorgPlanID = this.props.location.hash.replace("#","");

                if(this.state.zorgID !== zorgPlanID) this.getZorgPlan(zorgPlanID);
            }
        }
    }

    clickHandler(e) {
        let el = e.target;

        /* // Eventueel als child elements ook moeten gedecteerd worden
        while (el && el !== e.currentTarget && el.className !== "btnView") {
            el = el.parentNode;
        }
        */
       
        if (el && el.className.includes("btnView")) {
            this.setState({
                weergave: (this.state.weergave === "") ? "&compact" : ''
              }, () => {
                this.getZorgPlan(this.state.zorgID);
              });
        }
    }

    render() {
        if(this.state.content !== '') {
            return (
                <div className="row no-gutters">
                    <div className="col-xl-12">
                        <div onClick={this.clickHandler} dangerouslySetInnerHTML={{__html:this.state.content}}></div>
                    </div>
                </div>
            )
        } else {
            if(this.state.zorgplannen.length > 0) {
                return (
                    <div className="row no-gutters">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Zorgplannen</h5>
                                </div>
                                <div className="card-body">
                                    <div className="basic-list-group">
                                        <ListGroup>
                                        {this.state.zorgplannen.map((zorgplan, i) => (
                                            <Fragment key={i}>
                                                
                                                <ListGroup.Item action onClick={() => this.handleClick(zorgplan.zorgplanid)}>
                                                    <span className="pull-right">
                                                        <span className="btn btn-sm btn-success" onClick={(e) => { this.handleClick(zorgplan.zorgplanid, 2); e.stopPropagation(); }}>
                                                            <i className="fa fa-commenting text-white" /> <span class="d-none d-lg-inline-block text-white">enquete {zorgplan.enqueteid !== "" ? 'bekijken' : 'invullen'}</span>
                                                        </span> &nbsp; 
                                                        <span className="btn btn-sm btn-warning" onClick={(e) => { this.handleClick(zorgplan.zorgplanid, 1); e.stopPropagation(); }}>
                                                            <i className="fa fa-copy text-white" /> <span class="d-none d-lg-inline-block text-white">kopieren</span>
                                                        </span>
                                                    </span>
                                                    <strong>{zorgplan.zorgplanid}</strong> ({zorgplan.GeboorteJaar})<br />
                                                    Aangemaakt op {zorgplan.datumtijd_start}
                                                    </ListGroup.Item>
                                            </Fragment>
                                        ))}
                                        </ListGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="row no-gutters">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Zorgplannen</h5>
                                </div>
                                <div className="card-body">
                                    <p>Er zijn nog geen zorgplannen aangemaakt.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        
    }
  }  

export default withRouter(Zorgplannen);