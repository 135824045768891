import React from "react";
import { withRouter } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";

// image
class Home extends React.Component {
  static contextType = NVOSContext

  constructor(props) {
    super(props)

      this.state = {
          stats: [],
          news: []
      }
  }
  
  componentDidMount() {
    fetch(global.url + 'index.php?route=app/app/home').then(res => res.json())
      .then(
        (result) => {
            this.setState({
              stats: result.stats,
              news: result.news
            })

            if(result.user && result.user.user_id && result.user.user_id !== "") this.context.setUser(result.user);
            else if(result.user && result.user.extKey && result.user.extKey !== "") this.context.setUser(result.user);
        },
        (error) => {
            console.log("Ophalen mislukt! ", error);
        }
      ).then(window.scrollTo(0, 0));
  }

  render() {
    return (
      <div class="row">
					<div class="col-xl-9 col-sm-6">
						<div class="card overflow-hidden">
							<div class="card-header align-items-start border-0 pb-0">
								<div class="mr-auto">
									<p class="mb-2 fs-13"><i class="fa fa-caret-right scale5 mr-2 text-success" aria-hidden="true"></i> NVOS-Orthobanda</p>
									<h2 class="text-black mb-0 font-w600">Functiegericht verstrekken orthopedische hulpmiddelen</h2>
								</div>
							</div>
							<div class="card-body align-items-start border-0 pb-0 pt-0">
								<div class="mr-auto">
									<small>Van hulpvraag naar adequate, toetsbare hulpmiddelenzorg. Functioneringsgericht zorgplannen opstellen met aanbevelingen vanuit kennis-database.</small>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
						<div class="widget-stat card bg-success ">
							<div class="card-body p-4">
								<div class="media">
									<span class="mr-3">
										<i class="flaticon-381-calendar-1"></i>	
									</span>
									<div class="media-body text-white">
										<p class="mb-1">Zorgplannen</p>
										<h3 class="text-white">{this.state.stats.zorgplannen_totaal}</h3>
										<div class="progress mb-2 bg-secondary">
                                            <div class="progress-bar progress-animated bg-white" style={{width: this.state.stats.zorgplannen_procent + "%"}}></div>
                                        </div>
										<small>{this.state.stats.zorgplannen_maand} in afgelopen maand</small>
									</div>
								</div>
							</div>
						</div>
                    </div>
					
                    {this.state.news.map((news) => {
                      return (
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title" style={{textTransform: "none"}}>{news.titel}</h5>
                                </div>
                                <div class="card-body">
                                    <span class="text-info"><strong>{news.datum}</strong></span><br />
                                    <div dangerouslySetInnerHTML={{__html:news.inhoud}}></div>
                                </div>
                            </div>
                        </div>
                    )})}
				</div>
    )
  }
}

export default withRouter(Home);