import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { NVOSContext } from "../../../context/NVOSContext";

import { Wizard, Steps, Step } from 'react-albus';
import Select from 'react-select'
import { Button, Accordion, Card } from "react-bootstrap";

const successStyle = {backgroundColor: "#ecfae4", borderColor: "#ecfae4", color: "#68cf29" }
const dangerStyle = {backgroundColor: "#fee6ea", borderColor: "#fee6ea", color: "#f72b50" }

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius: "0.75rem",
    height: "100%"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "inherit"
  })
}

class ZorgplanMaken extends React.Component {
  static contextType = NVOSContext

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);

    this.onChange = this.onChange.bind(this);

      this.state = {
        data: null,
        activeDefault: -1,
        formData: null,
        result: null,
        hulpvraag: '',
        diagnose: '',
        highlightedItems: [],
        canProceed: false
      }
  }

  componentDidMount() {
    var hash = this.props.location.hash;
    
    if(hash != null && hash !== '') {
      hash = hash.split("#");
      hash = hash[hash.length - 1];

      fetch(global.url + 'index.php?route=app/app/jsonGetPlanID&zorgid=' + hash + '&plain').then(res => res.json())
        .then(
          (result) => {
              this.processZorgplan(result);
          },
          (error) => {
              console.log("Ophalen mislukt! ", error);
          }
        ).then(window.scrollTo(0, 0));
    } else {
      fetch(global.url + 'index.php?route=app/app/planmaken').then(res => res.json())
      .then(
        (result) => {
            let newFormData = null;
            if(result && result.extra && (result.extra.byear !== "" || result.extra.gender !== "")) {
              newFormData = new FormData();
              newFormData.set('geboorteDatum', result.extra.byear);
              newFormData.set('geslacht', result.extra.gender);
              newFormData.set('testmodus', result.extra.testmodus);
              newFormData.set('herhaling', result.extra.herhaling);
            }
            
            this.setState({
              data: result,
              activeDefault: -1,
              formData: newFormData,
              result: null,
              hulpvraag: '',
              diagnose: '',
              highlightedItems: [],
              canProceed: false
            })
            
            if(result.user && result.user.user_id && result.user.user_id !== "") this.context.setUser(result.user);
            else if(result.user && result.user.extKey && result.user.extKey !== "") this.context.setUser(result.user);
        },
        (error) => {
            console.log("Ophalen mislukt! ", error);
        }
      ).then(window.scrollTo(0, 0));
    }
  }

  componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
          this.componentDidMount();
      }
  }

  handleChange = (e) => {
    const newFormData = new FormData(e.target.form);
    
    if(this.state.formData != null) {
      for (var pair of this.state.formData.entries()) {
        if(newFormData.get(pair[0]) == null) newFormData.set(pair[0], pair[1]);
      }
    }
    
    this.setState({ formData: newFormData });
  }

  onChange = (e, type) => {
    const newFormData = new FormData();
    
    if(this.state.formData != null) {
      for (var pair of this.state.formData.entries()) {
        newFormData.set(pair[0], pair[1]);
      }
    }

    if(e && e.target) {
      newFormData.set(e.target.name, e.target.value);
      if(e.target.name === 'hulpvraag') {
        this.setState({ hulpvraag: e.target.value, formData: newFormData });
      } else {
        this.setState({ formData: newFormData });
      }
    } else {
      if(type && type === 2) {
        newFormData.set('diagnose', e.value);
        this.setState({ diagnose: e.value, formData: newFormData });
      } else {
        newFormData.set('geboorteDatum', e.value);
        this.setState({ formData: newFormData });
      }
    }
  }

  getDiagnoses = () => {
    const options = [];
    if(this.state.data != null) {
      this.state.data.diagnoses.map((diagnose, i) => (
        options.push({value: diagnose.Diagnose_Id, label: diagnose.Diagnose_Naam})
      ));
    }
    return options;
  };
  
  getDiagnose = (id) => {
    let result = {};
    if(this.state.data != null) {
      this.state.data.diagnoses.forEach(diagnose => {
        if(diagnose.Diagnose_Id === id) {
          result = {value: diagnose.Diagnose_Id, label: diagnose.Diagnose_Naam};
          return;
        }
      });
    }
    return result;
  };

  getBirthyears = () => {
    const options = [];
    for (var i = 1900; i <= new Date().getFullYear(); i++) {
      options.push({value: i, label: i});
    }
    return options;
  };

  setActiveDefault = (i) => {
      this.setState({
          activeDefault: i
      })
  }

  checkMain = (arr, type) => {
    var result = {style: null, keuze: 0};
    var check = null;

    if(type === 4) {
      Object.keys(arr).map((term, ii) => {
        var check = this.checkCategory(arr[term]);

        if(check != null && check.style != null) {
          result.style = check.style;
        }

        return check;
      })
    } else {
      for (var i = 0; i < arr.length; i++) {
        switch(type) {
          case 1:
            check = this.checkIcfTerm(arr[i]);
            break;
          case 2:
            check = this.checkFunctieEis(arr[i]);
            break;
          case 3: 
            check = this.checkComponent(arr[i]);
            break;
          default:
            break;
        }
      
        if(check != null && check.style != null) {
          result.style = check.style;
          if(result.style === dangerStyle) break;
        }
      }
    }

    return result;
  }

  checkIcfTerm = (icf) => {
    var result = {style: null, keuze: 0},
        current = this.state.formData.get(`icfid[${icf["ICF_Id"]}][voet]`);

    if(this.state.highlightedItems != null) {
      Object.values(this.state.highlightedItems).forEach(item => {
          if (item.ICF_Id === icf["ICF_Id"]) {
            result = {style: dangerStyle, keuze: parseInt(item.Functie_eisen_Id_Keuze_1_7) };
            return;
          }
      });
    }

    if(current != null) {
      result.style = successStyle;
    }

    return result;
  }

  checkFunctieEis = (fe) => {
    var result = {style: null, keuze: 0},
        current = this.state.formData.get(`functieeisid[${fe["Functie_eisen_Id"]}][voet]`);

    if(this.state.highlightedItems != null) {
      Object.values(this.state.highlightedItems).forEach(item => {
          let formValue = (this.state.formData != null) ? this.state.formData.get(`icfid[${item.ICF_Id}][voet]`) : '';
          if (item.Functie_eisen_Id === fe["Functie_eisen_Id"] && (formValue === '' || formValue === item["Radiobutton" + item.ICF_Subkeuze1_7])) {
            result = {style: dangerStyle, keuze: parseInt(item.Functie_eisen_Id_Keuze_1_7) };
            return;
          }
      });
    }

    if(current != null) {
      result.style = successStyle;
    }

    return result;
  }

  checkComponent = (comp) => {
    var result = {style: null, keuze: 0},
        current = this.state.formData.get(`compid[${comp["Product_componenten_Id"]}][voet]`);

    if(this.state.highlightedItems != null) {
      Object.values(this.state.highlightedItems).forEach(item => {
          let formValue = (this.state.formData != null) ? this.state.formData.get(`functieeisid[${item.Functie_eisen_Id}][voet]`) : '';
          if (item.Product_componenten_Id === comp["Product_componenten_Id"] && (formValue === '' || formValue === item["Radiobutton" + item.Functie_eisen_Id_Subkeuze1_7])) { // 
            result = {style: dangerStyle, keuze: parseInt(item.Product_componenten_Id_Keuze_1_7) };
            return;
          }
      });
    }

    if(current != null) {
      result.style = successStyle;
    }

    return result;
  }

  checkCategory = (cat) => {
    var result = {style: null, keuze: 0},
        current = this.state.formData.get(`catid[${cat["Product_Categorieen_ID"]}][voet]`);

    if(this.state.highlightedItems != null) {
      Object.values(this.state.highlightedItems).forEach(item => {
        if (item.selectid === cat["Product_Categorieen_ID"]) { // && formValue === item["Radiobutton" + item.Functie_eisen_Id_Subkeuze1_7]
            result = {style: dangerStyle, keuze: 1 };
            return;
          }
      });
    }

    if(current != null) {
      result.style = successStyle;
    }

    return result;
  }
  
  handlePrevious(event, step) {
      event.preventDefault();
    this.setState({
        activeDefault: -1
    }, () => {
        var url = '';
        switch(step) {
          case 1:
            url = 'ajaxGetICFbyDiagnose';
          break;
          case 2:  
            url = 'ajaxGetFEbyICF';
          break;
          case 3:
            url = 'ajaxGetCompByFE';
          break;
          case 4:
            url = 'ajaxGetCatByComp';
          break;
          case 5:
            this.handleSubmit();
          break;
          default:
            // ...
        }
        if(url !== '') {
          fetch(global.url + 'index.php?route=app/app/' + url, {
            method: 'POST',
            body: this.state.formData,
          }).then(res => res.json())
          .then(
            (result) => {
              console.log(url);
              console.log(result);
                this.setState({
                    highlightedItems: result
                })
            },
            (error) => {
                console.log("Ophalen mislukt! ", error);
            }
          );
        }
      });
  }

  handleNext(event, step) {
      event.preventDefault();
      const newFormData = new FormData(event.target.form);
      
      if(this.state.formData != null) {
        for (var pair of this.state.formData.entries()) {
          newFormData.set(pair[0], pair[1]);
        }
      }
      
    this.setState({
        formData: newFormData,
        activeDefault: -1
     }, () => {
        var url = '';
        switch(step) {
          case 1:
            url = 'ajaxGetICFbyDiagnose';
          break;
          case 2:  
            url = 'ajaxGetFEbyICF';
          break;
          case 3:
            url = 'ajaxGetCompByFE';
          break;
          case 4:
            url = 'ajaxGetCatByComp';
          break;
          case 5:
            this.handleSubmit();
          break;
          default:
            // ...
        }
        if(url !== '') {
          fetch(global.url + 'index.php?route=app/app/' + url, {
            method: 'POST',
            body: this.state.formData,
          }).then(res => res.json())
          .then(
            (result) => {
              console.log(url);
              console.log(result);
                this.setState({
                    highlightedItems: result
                })
            },
            (error) => {
                console.log("Ophalen mislukt! ", error);
            }
          );
        }

        if(step < 5) this.save();
      });

  }

  processZorgplan(zorgplan) {

    const newFormData = new FormData();
    
    newFormData.set('testmodus', zorgplan.algemeen.testmodus);
    newFormData.set('herhaling', zorgplan.algemeen.herhaling);
    newFormData.set('geslacht', zorgplan.algemeen.Geslacht);
    newFormData.set('geboorteDatum', zorgplan.algemeen.GeboorteJaar);
    newFormData.set('hulpvraag', zorgplan.algemeen.Hulpvraag);
    newFormData.set('diagnose', zorgplan.algemeen.DiagnoseID);
    
    for (let key in zorgplan['01icf']) {
      for (let keyX in zorgplan['01icf'][key]) {
        let antwoord = zorgplan['01icf'][key][keyX]['antwoord'];
        if(antwoord["voet"] !== '') newFormData.set(`icfid[${antwoord["dbid"]}][voet]`, antwoord["voet"]);
        newFormData.set(`icfid[${antwoord["dbid"]}][txt]`, antwoord["klacht"]);
      }
    }
    
    for (let key in zorgplan['02functieeis']) {
      for (let keyX in zorgplan['02functieeis'][key]) {
        let antwoord = zorgplan['02functieeis'][key][keyX]['antwoord'];
        if(antwoord["voet"] !== '') newFormData.set(`functieeisid[${antwoord["dbid"]}][voet]`, antwoord["voet"]);
        newFormData.set(`functieeisid[${antwoord["dbid"]}][txt]`, antwoord["klacht"]);
      }
    }

    for (let key in zorgplan['03prcomp']) {
      for (let keyX in zorgplan['03prcomp'][key]) {
        let antwoord = zorgplan['03prcomp'][key][keyX]['antwoord'];
        if(antwoord["voet"] !== '') newFormData.set(`compid[${antwoord["dbid"]}][voet]`, antwoord["voet"]);
        newFormData.set(`compid[${antwoord["dbid"]}][txt]`, antwoord["klacht"]);
      }
    }

    for (let key in zorgplan['04categorie']) {
      for (let keyX in zorgplan['04categorie'][key]) {
        let antwoord = zorgplan['04categorie'][key][keyX]['antwoord'];
        if(antwoord["voet"] !== '') newFormData.set(`catid[${antwoord["dbid"]}][voet]`, antwoord["voet"]);
        newFormData.set(`catid[${antwoord["dbid"]}][txt]`, antwoord["klacht"]);
      }
    }

    this.setState({
      data: zorgplan.data,
      hulpvraag: zorgplan.algemeen.Hulpvraag,
      diagnose: zorgplan.algemeen.DiagnoseID,
      formData: newFormData,
      activeDefault: -1
    });
  }

  save() {
    if(this.state.formData == null) return;
      fetch(global.url + 'index.php?route=app/app/ajaxZorgplanOpslaan', {
          method: 'POST',
          body: this.state.formData,
        }).then(res => res.json())
        .then(
          (result) => {
              console.log("Tussentijds opslaan gelukt! ", result.result);
          },
          (error) => {
              console.log("Tussentijds opslaan mislukt! ", error);
          }
        );
  }

  handleSubmit() {
    if(this.state.formData == null) return;
    
      fetch(global.url + 'index.php?route=app/app/ajaxZorgplanOpslaan', {
          method: 'POST',
          body: this.state.formData,
        }).then(res => res.json())
        .then(
          (result) => {
              this.setState({
                result: result.result
              });
          },
          (error) => {
              this.setState({
                result: "failed"
              })
              console.log("Opslaan mislukt! ", error);
          }
        ).then(window.scrollTo(0, 0));
  }

  render() {
    if(this.state.result == null) {
      return (
        <Fragment>
          <div className="row">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Nieuw zorgplan maken</h4>
                </div>
                <div className="card-body">
                  <form
                    onSubmit={(e) => {console.log('test'); e.preventDefault()}}
                    id="step-form-horizontal"
                    className="step-form-horizontal"
                  >
                    <Wizard>
                      <Steps>
                        <Step
                          id="basisgegevens"
                          render={({ next }) => (
                            <section>
                            <p class="text-muted"><strong class="text-info">NIEUW PLAN-ID: {this.state.data != null && this.state.data.newPlanID}</strong><br />
                            Geef hieronder de informatie over de klant op.</p>
                              <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <div className="form-group">
                                        <label className="text-label">Datamodus <sup>*</sup></label>
                                        <input type="hidden" name="newPlanID" value={this.state.data != null && this.state.data.newPlanIDdb} />
                                        <select onChange={this.onChange} name="testmodus" className="form-control" value={(this.state.formData != null) ? this.state.formData.get('testmodus') : ''}>
                                            <option value="1">Test</option>
                                            <option value="0">Productie</option>
                                          </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <div className="form-group">
                                        <label className="text-label">Verstrekking <sup>*</sup></label>
                                        <select onChange={this.onChange} name="herhaling" className="form-control" value={(this.state.formData != null) ? this.state.formData.get('herhaling') : ''}>
                                            <option value="0">Nieuw</option>
                                            <option value="1">Herhaling</option>
                                          </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <div className="form-group">
                                        <label className="text-label">Geslacht</label>
                                        <select onChange={this.onChange} name="geslacht" className="form-control" value={(this.state.formData != null) ? this.state.formData.get('geslacht') : ''}>
                                            <option value="">Maak een keuze</option>
                                            <option value="man">Man</option>
                                            <option value="vrouw">Vrouw</option>
                                          </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <div className="form-group">
                                        <label className="text-label">Geboorte jaar</label>
                                        <Select onChange={(e) => {this.onChange(e, 1);}} defaultValue={{}} value={(this.state.formData != null && this.state.formData.get('geboorteDatum') != null && this.state.formData.get('geboorteDatum') !== '') ? {value:this.state.formData.get('geboorteDatum'), label:this.state.formData.get('geboorteDatum')} : ''} className="form-control" styles={customStyles} options={this.getBirthyears()} name="geboorteDatum" placeholder="Selecteer geboorte jaar" />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 mb-3">
                                    <div className="form-group">
                                        <label className="text-label">Hulpvraag <sup>*</sup></label>
                                        <input
                                          type="text"
                                          name="hulpvraag"
                                          className="form-control"
                                          onChange={this.onChange}
                                          defaultValue={(this.state.formData != null) ? this.state.formData.get('hulpvraag') : ''}
                                          required
                                        />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 mb-3">
                                    <div className="form-group">
                                        <label className="text-label">Diagnose <sup>*</sup></label>
                                        <Select onChange={(e) => {this.onChange(e, 2);}} defaultValue={{}} value={(this.state.formData != null && this.state.formData.get('diagnose') != null && this.state.formData.get('diagnose') !== '') ? this.getDiagnose(this.state.formData.get('diagnose')) : ''} className="form-control" styles={customStyles} options={this.getDiagnoses()} name="diagnose" placeholder="Selecteer diagnose" />
                                    </div>
                                  </div>
                              </div>
                              <Button disabled={this.state.diagnose === '' || this.state.hulpvraag === ''} onClick={(e) => {this.handleNext(e, 1); next();}} className="mr-2" variant="primary">Naar volgende stap</Button>
                            </section>
                          )}
                        />
                        <Step
                          id="icf"
                          render={({ next, previous }) => (
                            <section>
                            <p class="text-muted"><strong class="text-info">NIEUW PLAN-ID: {this.state.data != null && this.state.data.newPlanID}</strong><br />
                            Geef hieronder alleen de hoogst relevante stoornissen/beperkingen op. Stoornissen moeten dusdanig ernstig zijn dat confectieschoeisel zonder orthopedische voorzieningen geen optie biedt.</p>
                              <div className="row">
                                  <div className="col-lg-12 mb-2">
                                  <Accordion
                                      className="accordion-primary"
                                  >
                                      {Object.keys(this.state.data.icfTermen).map((icf, i) => {
                                        var mainCheck = this.checkMain(this.state.data.icfTermen[icf], 1);
                                        return (
                                          <div className="accordion__item" key={i}>
                                              <Accordion.Toggle
                                              as={Card.Text}
                                              eventKey={`${i}`}
                                              className={`accordion__header rounded-lg ${
                                                  this.state.activeDefault === i ? "" : "collapsed"
                                              }`}
                                              style={mainCheck.style}
                                              onClick={() =>
                                                  this.setActiveDefault(this.state.activeDefault === i ? -1 : i)
                                              }
                                              >
                                              <span className="accordion__header--text">{icf}</span>
                                              <span className="accordion__header--indicator"></span>
                                              </Accordion.Toggle>
                                              <Accordion.Collapse eventKey={`${i}`}>
                                              <div className="accordion__body--text">
                                                <Accordion
                                                    className="accordion-left-indicator"
                                                >
                                                    {this.state.data.icfTermen[icf].map((term, ii) => {
                                                      var check = this.checkIcfTerm(term);
                                                      return (
                                                        <div className="accordion__item" key={ii}>
                                                            <Accordion.Toggle
                                                            as={Card.Text}
                                                            eventKey={`${ii}`}
                                                            className={`accordion__header rounded-lg collapsed`}
                                                            style={check.style}
                                                            >
                                                            <span className="accordion__header--text">{term["Korte omschrijving"]}</span>
                                                            <span className="accordion__header--indicator"></span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={`${ii}`}>
                                                            <div className="accordion__body--text">
                                                              <p><span class="text-info">{term["ICF_Naam"]}</span><br /><span class="text-primary">{term["ICF_Omschrijving"]}</span></p>
                                                              <input type="hidden" name={`icfid[${term["ICF_Id"]}][advies]`} value={(check.keuze === 0) ? '' : 'ja'} />
                                                              <div className="form-group mb-0">
                                                                <div className="radio">
                                                                  <label>
                                                                    <input onChange={this.handleChange} type="radio" defaultChecked={(this.state.formData != null && this.state.formData.get(`icfid[${term["ICF_Id"]}][voet]`) === '')} name={`icfid[${term["ICF_Id"]}][voet]`} value="" /> Negeer deze keuze
                                                                  </label>
                                                                </div>
                                                              {Array.from(Array(8), (e, ri) => {
                                                                if(ri > 0 && term["Radiobutton"+ri] !== "") {
                                                                  return (
                                                                    <div className="radio">
                                                                      <label>
                                                                        <input onChange={this.handleChange} type="radio" defaultChecked={(this.state.formData != null && this.state.formData.get(`icfid[${term["ICF_Id"]}][voet]`) === term["Radiobutton"+ri])} name={`icfid[${term["ICF_Id"]}][voet]`} value={term["Radiobutton"+ri]} /> <span style={ check.keuze === ri ? { fontWeight: 'bold' } : { fontWeight: 'normal' } }>{term["Radiobutton"+ri]}</span>
                                                                      </label>
                                                                    </div>
                                                                  )
                                                                } else return '';
                                                              })}
                                                              </div>
                                                              <div className="form-group">
                                                                  <input onChange={this.handleChange}
                                                                    type="text"
                                                                    name={`icfid[${term["ICF_Id"]}][txt]`}
                                                                    placeholder={term["Tekstveldomschrijving"]}
                                                                    defaultValue={(this.state.formData != null) ? this.state.formData.get(`icfid[${term["ICF_Id"]}][txt]`) : ''}
                                                                    className="form-control"
                                                                  />
                                                              </div>
                                                            </div>
                                                            </Accordion.Collapse>
                                                        </div>
                                                    )})}
                                                </Accordion>
                                              </div>
                                              </Accordion.Collapse>
                                          </div>
                                      )})}
                                  </Accordion>
                                  </div>
                              </div>
                              <Button onClick={(e) => {this.handlePrevious(e, 0); previous();}} className="mr-2" variant="light">Vorige</Button>
                              <Button onClick={(e) => {this.handleNext(e, 2); next();}} className="mr-2" variant="primary">Naar functie eisen</Button>
                            </section>
                          )}
                        />
                        <Step
                          id="functieeisen"
                          render={({ next, previous }) => (
                            <section>
                            <p class="text-muted"><strong class="text-info">NIEUW PLAN-ID: {this.state.data != null && this.state.data.newPlanID}</strong><br />
                            Geef hieronder de relevante functie-eisen aan. In kleur is aangegeven welke functie-eisen aanbevolen worden.</p>
                              <div className="row">
                                  <div className="col-lg-12 mb-2">
                                  <Accordion
                                      className="accordion-primary"
                                  >
                                      {Object.keys(this.state.data.functieEisen).map((functie, i) => {
                                        var mainCheck = this.checkMain(this.state.data.functieEisen[functie], 2);
                                        return (
                                          <div className="accordion__item" key={i}>
                                              <Accordion.Toggle
                                              as={Card.Text}
                                              eventKey={`${i}`}
                                              className={`accordion__header rounded-lg ${
                                                  this.state.activeDefault === i ? "" : "collapsed"
                                              }`}
                                              style={mainCheck.style}
                                              onClick={() =>
                                                  this.setActiveDefault(this.state.activeDefault === i ? -1 : i)
                                              }
                                              >
                                              <span className="accordion__header--text">{functie}</span>
                                              <span className="accordion__header--indicator"></span>
                                              </Accordion.Toggle>
                                              <Accordion.Collapse eventKey={`${i}`}>
                                              <div className="accordion__body--text">
                                                <Accordion
                                                    className="accordion-left-indicator"
                                                >
                                                    {this.state.data.functieEisen[functie].map((term, ii) => { 
                                                        var check = this.checkFunctieEis(term);
                                                        return(
                                                        <div className="accordion__item" key={ii}>
                                                            <Accordion.Toggle
                                                            as={Card.Text}
                                                            eventKey={`${ii}`}
                                                            className={`accordion__header rounded-lg collapsed`}
                                                            style={check.style}
                                                            >
                                                            <span className="accordion__header--text">{term["Korte omschrijving"]}</span>
                                                            <span className="accordion__header--indicator"></span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={`${ii}`}>
                                                            <div className="accordion__body--text">
                                                              <p><span class="text-info">{term["Functie_eisen_Omschrijving"]}</span><br /><span class="text-primary">{term["Glossary"]}</span></p>
                                                              <input type="hidden" name={`functieeisid[${term["Functie_eisen_Id"]}][advies]`} value={(check.keuze === 0) ? '' : 'ja'} />
                                                              <div className="form-group mb-0">
                                                                <div className="radio">
                                                                  <label>
                                                                    <input onChange={this.handleChange} type="radio" defaultChecked={(this.state.formData != null && this.state.formData.get(`functieeisid[${term["Functie_eisen_Id"]}][voet]`) === '')} name={`functieeisid[${term["Functie_eisen_Id"]}][voet]`} value="" /> Negeer deze keuze
                                                                  </label>
                                                                </div>
                                                              {Array.from(Array(8), (e, ri) => {
                                                                if(ri > 0 && term["Radiobutton"+ri] !== "") {
                                                                  return (
                                                                    <div className="radio">
                                                                      <label>
                                                                        <input onChange={this.handleChange} type="radio" defaultChecked={(this.state.formData != null && this.state.formData.get(`functieeisid[${term["Functie_eisen_Id"]}][voet]`) === term["Radiobutton"+ri])} name={`functieeisid[${term["Functie_eisen_Id"]}][voet]`} value={term["Radiobutton"+ri]} /> <span style={ check.keuze === ri ? { fontWeight: 'bold' } : { fontWeight: 'normal' } }>{term["Radiobutton"+ri]}</span>
                                                                      </label>
                                                                    </div>
                                                                  )
                                                                } else return '';
                                                              })}
                                                              </div>
                                                              <div className="form-group">
                                                                  <input onChange={this.handleChange}
                                                                    type="text"
                                                                    name={`functieeisid[${term["Functie_eisen_Id"]}][txt]`}
                                                                    placeholder={term["Tekstveldomschrijving"]}
                                                                    defaultValue={(this.state.formData != null) ? this.state.formData.get(`functieeisid[${term["Functie_eisen_Id"]}][txt]`) : ''}
                                                                    className="form-control"
                                                                  />
                                                              </div>
                                                            </div>
                                                            </Accordion.Collapse>
                                                        </div>
                                                    )})}
                                                </Accordion>
                                              </div>
                                              </Accordion.Collapse>
                                          </div>
                                      )})}
                                  </Accordion>
                                  </div>
                              </div>
                              <Button onClick={(e) => {this.handlePrevious(e, 1); previous();}} className="mr-2" variant="light">Vorige</Button>
                              <Button onClick={(e) => {this.handleNext(e, 3); next();}} className="mr-2" variant="primary">Naar componenten</Button>
                            </section>
                          )}
                        />
                        <Step
                          id="componenten"
                          render={({ next, previous }) => (
                            <section>
                            <p class="text-muted"><strong class="text-info">NIEUW PLAN-ID: {this.state.data != null && this.state.data.newPlanID}</strong><br />
                            Geef hieronder de relevante componenten aan, in kleur is aangegeven welke componenten aanbevolen worden.</p>
                              <div className="row">
                                  <div className="col-lg-12 mb-2">
                                  <Accordion
                                      className="accordion-primary"
                                  >
                                      {Object.keys(this.state.data.componenten).map((component, i) => {
                                        var mainCheck = this.checkMain(this.state.data.componenten[component], 3);
                                        return (
                                          <div className="accordion__item" key={i}>
                                              <Accordion.Toggle
                                              as={Card.Text}
                                              eventKey={`${i}`}
                                              className={`accordion__header rounded-lg ${
                                                  this.state.activeDefault === i ? "" : "collapsed"
                                              }`}
                                              style={mainCheck.style}
                                              onClick={() =>
                                                  this.setActiveDefault(this.state.activeDefault === i ? -1 : i)
                                              }
                                              >
                                              <span className="accordion__header--text">{component}</span>
                                              <span className="accordion__header--indicator"></span>
                                              </Accordion.Toggle>
                                              <Accordion.Collapse eventKey={`${i}`}>
                                              <div className="accordion__body--text">
                                                <Accordion
                                                    className="accordion-left-indicator"
                                                >
                                                    {this.state.data.componenten[component].map((term, ii) => {
                                                      var check = this.checkComponent(term);
                                                      return (
                                                        <div className="accordion__item" key={ii}>
                                                            <Accordion.Toggle
                                                            as={Card.Text}
                                                            eventKey={`${ii}`}
                                                            className={`accordion__header rounded-lg collapsed`}
                                                            style={check.style}
                                                            >
                                                            <span className="accordion__header--text">{term["Korte omschrijving"]}</span>
                                                            <span className="accordion__header--indicator"></span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={`${ii}`}>
                                                            <div className="accordion__body--text">
                                                              <p><span class="text-info">{term["Product_componenten_Naam"]}</span></p>
                                                              <input type="hidden" name={`compid[${term["Product_componenten_Id"]}][Filteren]`} value={term["Goedkoop_adequaat_in_theorie"]} />
                                                              <input type="hidden" name={`compid[${term["Product_componenten_Id"]}][advies]`} value={(check.keuze === 0) ? '' : 'ja'} />
                                                              <div className="form-group mb-0">
                                                                <div className="radio">
                                                                  <label>
                                                                    <input onChange={this.handleChange} type="radio" defaultChecked={(this.state.formData != null && this.state.formData.get(`compid[${term["Product_componenten_Id"]}][voet]`) === '')} name={`compid[${term["Product_componenten_Id"]}][voet]`} value="" /> Negeer deze keuze
                                                                  </label>
                                                                </div>
                                                              {Array.from(Array(8), (e, ri) => {
                                                                if(ri > 0 && term["Radiobutton"+ri] !== "") {
                                                                  return (
                                                                    <div className="radio">
                                                                      <label>
                                                                        <input onChange={this.handleChange} type="radio" defaultChecked={(this.state.formData != null && this.state.formData.get(`compid[${term["Product_componenten_Id"]}][voet]`) === term["Radiobutton"+ri])} name={`compid[${term["Product_componenten_Id"]}][voet]`} value={term["Radiobutton"+ri]} /> <span style={ check.keuze === ri ? { fontWeight: 'bold' } : { fontWeight: 'normal' } }>{term["Radiobutton"+ri]}</span>
                                                                      </label>
                                                                    </div>
                                                                  )
                                                                } else return '';
                                                              })}
                                                              </div>
                                                              <div className="form-group">
                                                                  <input onChange={this.handleChange}
                                                                    type="text"
                                                                    name={`compid[${term["Product_componenten_Id"]}][txt]`}
                                                                    placeholder={term["Tekstveldomschrijving"]}
                                                                    defaultValue={(this.state.formData != null) ? this.state.formData.get(`compid[${term["Product_componenten_Id"]}][txt]`) : ''}
                                                                    className="form-control"
                                                                  />
                                                              </div>
                                                            </div>
                                                            </Accordion.Collapse>
                                                        </div>
                                                    )})}
                                                </Accordion>
                                              </div>
                                              </Accordion.Collapse>
                                          </div>
                                      )})}
                                  </Accordion>
                                  </div>
                              </div>
                              <Button onClick={(e) => {this.handlePrevious(e, 2); previous();}} className="mr-2" variant="light">Vorige</Button>
                              <Button onClick={(e) => {this.handleNext(e, 4); next();}} className="mr-2" variant="primary">Naar categorieën</Button>
                            </section>
                          )}
                        />
                        <Step
                          id="categorieen"
                          render={({ previous }) => (
                            <section>
                            <p class="text-muted"><strong class="text-info">NIEUW PLAN-ID: {this.state.data != null && this.state.data.newPlanID}</strong><br />
                            Geef hieronder de relevante product-categorie en/of dienst aan, in kleur is aangegeven welke categorieën aanbevolen worden.</p>
                              <div className="row">
                                  <div className="col-lg-12 mb-2">
                                    <input type="hidden" name="finalStep" value="1" />
                                  <Accordion
                                      className="accordion-primary"
                                  >
                                      {Object.keys(this.state.data.categorieen).map((categorie, i) => {
                                        var mainCheck = this.checkMain(this.state.data.categorieen[categorie], 4);
                                        return (
                                          <div className="accordion__item" key={i}>
                                              <Accordion.Toggle
                                              as={Card.Text}
                                              eventKey={`${i}`}
                                              className={`accordion__header rounded-lg ${
                                                  this.state.activeDefault === i ? "" : "collapsed"
                                              }`}
                                              style={mainCheck.style}
                                              onClick={() =>
                                                  this.setActiveDefault(this.state.activeDefault === i ? -1 : i)
                                              }
                                              >
                                              <span className="accordion__header--text">{categorie}</span>
                                              <span className="accordion__header--indicator"></span>
                                              </Accordion.Toggle>
                                              <Accordion.Collapse eventKey={`${i}`}>
                                              <div className="accordion__body--text">
                                                <Accordion
                                                    className="accordion-left-indicator"
                                                >
                                                    {Object.keys(this.state.data.categorieen[categorie]).map((term, ii) => {
                                                      var check = this.checkCategory(this.state.data.categorieen[categorie][term]);
                                                      return (
                                                        <div className="accordion__item" key={ii}>
                                                            <Accordion.Toggle
                                                            as={Card.Text}
                                                            eventKey={`${ii}`}
                                                            className={`accordion__header rounded-lg collapsed`}
                                                            style={check.style}
                                                            >
                                                            <span className="accordion__header--text">{this.state.data.categorieen[categorie][term]["Product_Categorieen_Omschrijving"]}</span>
                                                            <span className="accordion__header--indicator"></span>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={`${ii}`}>
                                                            <div className="accordion__body--text">
                                                              <p><span class="text-info">{this.state.data.categorieen[categorie][term]["Product_Categorieen_Omschrijving"]}</span></p>
                                                              <input type="hidden" name={`catid[${this.state.data.categorieen[categorie][term]["Product_Categorieen_ID"]}][advies]`} value={(check.keuze === 0) ? '' : 'ja'} />
                                                              <div className="form-group mb-0">
                                                                <div className="radio">
                                                                  <label>
                                                                    <input onChange={this.handleChange} type="radio" defaultChecked={(this.state.formData != null && this.state.formData.get(`catid[${this.state.data.categorieen[categorie][term]["Product_Categorieen_ID"]}][voet]`) === '')} name={`catid[${this.state.data.categorieen[categorie][term]["Product_Categorieen_ID"]}][voet]`} value="" /> Negeer deze keuze
                                                                  </label>
                                                                </div>
                                                              {Array.from(Array(8), (e, ri) => {
                                                                if(ri > 0 && this.state.data.categorieen[categorie][term]["Radiobutton"+ri] !== "") {
                                                                  return (
                                                                    <div className="radio">
                                                                      <label>
                                                                        <input onChange={this.handleChange} type="radio" defaultChecked={(this.state.formData != null && this.state.formData.get(`catid[${this.state.data.categorieen[categorie][term]["Product_Categorieen_ID"]}][voet]`) === this.state.data.categorieen[categorie][term]["Radiobutton"+ri])} name={`catid[${this.state.data.categorieen[categorie][term]["Product_Categorieen_ID"]}][voet]`} value={this.state.data.categorieen[categorie][term]["Radiobutton"+ri]} /> {this.state.data.categorieen[categorie][term]["Radiobutton"+ri]}
                                                                      </label>
                                                                    </div>
                                                                  )
                                                                } else return '';
                                                              })}
                                                              </div>
                                                              <div className="form-group">
                                                                  <input onChange={this.handleChange}
                                                                    type="text"
                                                                    name={`catid[${this.state.data.categorieen[categorie][term]["Product_Categorieen_ID"]}][txt]`}
                                                                    placeholder={this.state.data.categorieen[categorie][term]["Tekstveldomschrijving"]}
                                                                    defaultValue={(this.state.formData != null) ? this.state.formData.get(`catid[${this.state.data.categorieen[categorie][term]["Product_Categorieen_ID"]}][txt]`) : ''}
                                                                    className="form-control"
                                                                  />
                                                              </div>
                                                            </div>
                                                            </Accordion.Collapse>
                                                        </div>
                                                    )})}
                                                </Accordion>
                                              </div>
                                              </Accordion.Collapse>
                                          </div>
                                      )})}
                                  </Accordion>
                                  </div>
                              </div>
                              <Button onClick={(e) => {this.handlePrevious(e, 3); previous();}} className="mr-2" variant="light">Vorige</Button>
                              <Button onClick={(e) => {this.handleNext(e, 5)}} className="mr-2" variant="success">Zorgplan afronden en opslaan</Button>
                            </section>
                          )}
                        />
                      </Steps>
                    </Wizard>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if(this.state.result === 'success') {
      return (
        <div className="row no-gutters">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Nieuw zorgplan maken</h5>
                    </div>
                    <div class="card-body">
                        <p class="text-success">Uw zorgplan is succesvol opgeslagen!</p>
                        <p>PLAN-ID: {this.state.data.newPlanID}</p>
                    </div>
                </div>
            </div>
        </div>
    )
    } else if(this.state.result === 'failed') {
      return (
        <div className="row no-gutters">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Nieuw zorgplan maken</h5>
                    </div>
                    <div class="card-body">
                        <p class="text-danger">Er is helaas een onbekende fout opgetreden bij het opslaan van uw zorgplan.</p>
                    </div>
                </div>
            </div>
        </div>
    )
    }
  }
};

export default withRouter(ZorgplanMaken);
