import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

// image
import logo from "../../../images/custom/nvos.png";
import { default as emojis } from "../../emojis"

class Register extends React.Component {
  
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    
    this.state = {
      loginResult: ""
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    
    fetch(global.url + 'index.php?route=login/login/ajaxregistreren', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
          console.log(result);
          this.setState({
            result: result.result
        })
      },
      (error) => {
          this.setState({
            result: "failed"
          })
          console.log("Registreren mislukt! ", error);
      }
    ).then(window.scrollTo(0, 0));
  }

  render() {
    return (
      <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <Link to="/">
                          <img src={logo} alt="" />
                        </Link>
                      </div>
                      
                      <h4 className="text-center mb-4 ">Nieuw account aanmaken</h4>

                      {this.state.result === "failed" &&
                        <Alert
                          variant="danger"
                          className="fade show"
                        >
                          {emojis.error}
                          <strong>Mislukt!</strong> Registratie mislukt. Controleer alle ingevulde velden en probeer het nogmaals.
                        </Alert>
                      }
                      {this.state.result === "success" &&
                      <Alert
                        variant="success"
                        className="fade show"
                      >
                        {emojis.error}
                        <strong>Gelukt!</strong> Registratie gelukt. U kunt nu inloggen.
                      </Alert>
                      }
                      {this.state.result !== "success" &&
                      <div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Bedrijfsnaam</strong>
                          </label>
                          <input
                            name="bedrijf"
                            type="text"
                            className="form-control"
                            placeholder="Bedrijfsnaam"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Functie</strong>
                          </label>
                          <input
                            name="functie"
                            type="text"
                            className="form-control"
                            placeholder="Functie"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Certificaat nummer</strong>
                          </label>
                          <input
                            name="certificaat"
                            type="text"
                            className="form-control"
                            placeholder="Certificaat nummer"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Voornaam</strong>
                          </label>
                          <input
                            name="voornaam"
                            type="text"
                            className="form-control"
                            placeholder="Voornaam"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Achternaam</strong>
                          </label>
                          <input
                            name="achternaam"
                            type="text"
                            className="form-control"
                            placeholder="Achternaam"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Telefoonnummer</strong>
                          </label>
                          <input
                            name="telefoon"
                            type="text"
                            className="form-control"
                            placeholder="Telefoonnummer"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>E-mailadres</strong>
                          </label>
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="voorbeeld@domein.com"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Wachtwoord</strong>
                          </label>
                          <input
                            name="wachtwoord"
                            type="password"
                            className="form-control"
                            placeholder="Wachtwoord"
                          />
                          <input name="klantnieuw" value="ja" type="hidden" />
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Aanmelden 
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-3">
                        <p className="pull-left">
                        Ik ga akkoord met de{" "}
                            <a href={global.url+'app/terms.html'} rel="noreferrer" target="_blank">
                             voorwaarden
                            </a>
                        </p>
                        <p className="pull-right">
                          Heeft u al een account?{" "}
                          <Link className="text-primary" to="/inloggen">
                            Inloggen
                          </Link>
                        </p>
                      </div>
                      </div>
                      }
                    </div>
                  </div>
                </div>
    )
  }
}

export default withRouter(Register);