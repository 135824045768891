import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { NVOSContext } from "../../../context/NVOSContext";

// image
import logo from "../../../images/custom/nvos.png";
import { default as emojis } from "../../emojis"


class ForgotPassword extends React.Component {
  static contextType = NVOSContext

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    
    this.state = {
      result: ""
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    
    fetch(global.url + 'index.php?route=login/login/wwvergeten', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        this.setState({
          result: result.result
      })
      },
      (error) => {
        this.setState({
          result: "failed"
        })
        console.log("Registreren mislukt! ", error);
      }
    ).then(window.scrollTo(0, 0));
  }

  render() {
    return (
    <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Wachtwoord vergeten</h4>
                      {this.state.result === "failed" &&
                        <Alert
                          variant="danger"
                          className="fade show"
                        >
                          {emojis.error}
                          <strong>Mislukt!</strong> Wachtwoord opvragen mislukt. Mailadres niet gevonden.
                        </Alert>
                      }
                      {this.state.result === "success" &&
                      <Alert
                        variant="success"
                        className="fade show"
                      >
                        {emojis.error}
                        <strong>Gelukt!</strong> Kijk in uw mailbox voor verdere instructies.
                      </Alert>
                      }
                      {this.state.result !== "success" &&
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label className="">
                          <strong>E-mailadres</strong>
                        </label>
                        <input
                        name="username"
                          type="email"
                          className="form-control"
                          placeholder="Voer uw mailadres in om uw wachtwoord te herstellen."
                          defaultValue=""
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Opvragen
                        </button>
                      </div>
                    </form>
  }
                  </div>
                </div>
              </div>
    )
  }
}

export default withRouter(ForgotPassword);