import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright {" "}{d.getFullYear()} © NVOS-Orthobanda - Ontwikkeld door {" "}
          <a rel="noreferrer" href="https://gewoon-peter.nl/" target="_blank">
          Gewoon Peter
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
